import UserLogo from "./UserLogo.js";
import editLogo from "../Assets/edit-1.png";
import featuredIcon from "../Assets/featured-icon.png";
import matesIcon from "../Assets/mates.png";
import calender from "../Assets/calender.png";
import peopleIcon from "../Assets/man-woman.png";
import degreeCap from "../Assets/degree-cap.png";
import serachInEye from "../Assets/eye-search.png";
import recycleBin from "../Assets/recycle-bin-icon.png";
import upload_Files from "../Assets/upload Files.png";
import briefCaseIcon from "../Assets/briefcase-icon.png";
import { forwardRef, useEffect, useRef, useState } from "react";
import Passions from "./Passions.js";
import { useNavigate } from "react-router-dom";
import FormModal from "./FormModal.js";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "./Loading.js";
import {
  Autocomplete,
  LoadScript,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
function Profile({
  userData,
  user = true,
  setShowPaymentModal,
  setImageModal,
  setDegree,
  education,
  state,
  profession,
  document,
  setDocument,
  questions,
  setQuestions,
  setEducation = { setEducation },
  setProfession = { setProfession },
  compatibility,
}) {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const feature = false;

  const [userQualification, setUserQualification] = useState([]);
  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      if (user) {
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `api/get/user/profile/${state?.user?.id}`,
            {
              headers: {
                Authorization: `Bearer ${state?.token}`,
              },
            }
          )
          .then((res) => {
            setUserQualification((prev) => [
              ...prev,
              ...res?.data?.qualifications,
            ]);
            setProfessions((prev) => [...prev, ...res?.data?.professions]);
          })
          .catch((err) => {
            if (err?.response?.data?.message == "Token has expired") {
              toast.error("Your token has been expired again sign in");

              return;
            }
            if (state.login == true) {
              toast.error("There is something went wrong!");
            }
          });
      }
    };
    getUser();
  }, []);

  let profileCompletion = null;

  const { login } = useSelector((state) => {
    return state.user;
  });

  if (user) {
    const calculateProfileCompletion = (userData) => {
      let totalFields = 24;

      let completedFields = 0;

      const fieldCheck = [
        "age",
        "city_of_residence",
        "country_of_residence",
        "dob",
        "email",
        "ethnicity",
        "financial_status",
        "gender",
        "height",
        "highest_qualification",
        "hobbies",
        "home",
        "home_height",
        "home_size",
        "image",
        "marital_status",
        "name",
        "native_city",
        "native_country",
        "native_state",
        "phone",
        "religion",
        "sect",
        "state_of_residence",
      ];

      // Check if each field exists and is not an empty string
      fieldCheck.forEach((field) => {
        if (userData?.[field] != "" && userData?.[field]) {
          completedFields += 1;
        }
      });

      if (
        userData?.native_country == "Pakistan" ||
        userData?.native_country == "India" ||
        userData?.native_country == "Bangladesh"
      ) {
        if (userData?.cast != "" && userData?.cast) {
          totalFields += 1;
          completedFields += 1;
        }
      }
      if (userData?.marital_status && userData?.marital_status !== "single") {
        if (userData?.no_of_childs != "" && userData?.no_of_childs) {
          totalFields += 1;
          completedFields += 1;
        }
      }

      // Combined check for education, profession, or questions
      if (userData?.education || userData?.profession || userData?.questions) {
        completedFields += 1; // Only increment once if any of these fields exist
      }

      // Calculate the completion percentage
      const completionPercentage = (completedFields / totalFields) * 100;

      return Math.floor(completionPercentage);
    };
    profileCompletion = calculateProfileCompletion(userData);
  }

  return (
    <>
      <FormModal
        className={"modal-md"}
        show={show}
        handleClose={() => {
          setShow(false);
          setEdit(null);
        }}
      >
        <ModalBody
          userQualification={userQualification}
          edit={edit}
          setEducation={setEducation}
          state={state}
          setShow={setShow}
          heading={"Add College Education"}
          buttonText={"Submit"}
        />
      </FormModal>
      <FormModal
        className={"modal-md"}
        show={show2}
        handleClose={() => {
          setShow2(false);
          setEdit(null);
        }}
      >
        <ModalBody2
          professions={professions}
          edit={edit}
          setProfession={setProfession}
          state={state}
          setShow2={setShow2}
          buttonText={"Submit"}
          setExperince={setProfession}
        />
      </FormModal>
      <FormModal
        className={"modal-md"}
        show={show3}
        handleClose={() => {
          setShow3(false);
          setEdit(null);
        }}
      >
        <BussinessModel
          edit={edit}
          setProfession={setProfession}
          state={state}
          show={show3}
          buttonText={"Submit"}
          setExperince={setProfession}
          setShow3={setShow3}
        />
      </FormModal>
      <FormModal
        className={"modal-md"}
        show={show4}
        handleClose={() => setShow4(false)}
      >
        <DocumentModel
          setDocument={setDocument}
          show={show4}
          setShow4={setShow4}
          state={state}
        />
      </FormModal>
      <div className="container-fluid profile mt-4 px-2 px-lg-4 px-xl-5">
        <div className="row mx-0">
          <div className="col-md-7 col-lg-8 col-xl-9">
            <div className="profile-about rounded-3 pb-4">
              <div className="about-header position-relative">
                {feature && (
                  <img
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "20px",
                      top: "20px",
                    }}
                    src={featuredIcon}
                    alt=""
                  />
                )}
              </div>
              <div className="about-logo-container px-2 px-sm-3 px-lg-4 px-xl-5 pb-4 d-flex justify-content-between align-items-end">
                <UserLogo
                  user={false}
                  image={userData?.image}
                  gender={userData?.gender}
                />
                {user && (
                  <img
                    className="edit-logo pointer"
                    onClick={() => {
                      navigate("/edit-profile");
                    }}
                    src={editLogo}
                    alt=""
                  />
                )}
              </div>
              <div className="px-2 px-sm-3 px-lg-4 px-xl-5">
                <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row pb-3 justify-content-between align-items-start">
                  <div>
                    <div className="d-flex gap-4 pb-3 align-items-center">
                      <h4
                        style={{ textTransform: "capitalize" }}
                        className="font-600 mb-0"
                      >
                        {userData?.name || "N/A"}
                      </h4>
                      <div className="tick-logo d-flex justify-content-center align-items-center">
                        <span className="fa-solid fa-check"></span>
                      </div>
                      {userData?.feature_profile_status == "by admin" && (
                        <img src={featuredIcon} width="30" />
                      )}
                    </div>
                    <h6>Age {userData?.age ? userData?.age : "N/A"}</h6>
                    <h6>
                      {userData?.profession ? userData.profession : "N/A"}
                    </h6>
                    <h6>
                      {userData?.highest_qualification
                        ? userData.highest_qualification
                        : "N/A"}
                    </h6>

                    {login == true && (
                      <div>
                        <div className="percentage position-relative">
                          <div
                            style={
                              user
                                ? { width: `${profileCompletion}%` }
                                : { width: `${compatibility}%` }
                            }
                            className="percentage-bar"
                          ></div>
                        </div>
                        <p className="mb-0 mt-1 font-600 text-small">
                          {user ? profileCompletion : compatibility}%{" "}
                          {user ? "Completed" : "Compatible"}
                        </p>
                      </div>
                    )}
                  </div>
                  {!feature && user == true && (
                    <div
                      onClick={() => setShowPaymentModal(true)}
                      className="pointer feature-profile-btn my-2 my-sm-0 p-2 d-flex justify-content-between align-items-end rounded-1 my-md-3 my-lg-0"
                    >
                      <div>
                        <p className="mb-0 small font-700">Featured profile</p>
                        <p className="mb-0" style={{ fontSize: "10px" }}>
                          By featuring your profile, you'll gain the ability to
                          create specialized posts
                        </p>
                      </div>
                      <div>
                        <img
                          className="feature-icon"
                          src={featuredIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  {userData?.feature_profile != 1 && user == false && (
                    <div
                      onClick={() => setShowPaymentModal(true)}
                      className="pointer feature-profile-btn my-2 my-sm-0 p-2 d-flex justify-content-between align-items-end rounded-1 my-md-3 my-lg-0"
                    >
                      <div>
                        <p className="mb-0 small font-700">Featured profile</p>
                        <p className="mb-0" style={{ fontSize: "10px" }}>
                          By featuring this profile,{" "}
                          {userData?.gender == "Male" ? "he'll" : "she'll"} gain
                          the ability to create specialized posts
                        </p>
                      </div>
                      <div>
                        <img
                          className="feature-icon"
                          src={featuredIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="basic-detail d-flex gap-lg-5 justify-content-between justify-content-lg-start">
                  <div className="d-flex align-itesm-start gap-1">
                    <img
                      src={peopleIcon}
                      className="basic-detail-icon"
                      alt=""
                    />
                    <div>
                      <h6 className="mb-1">Gender</h6>
                      <h6 className="text-light">
                        {userData?.gender?.charAt(0)?.toUpperCase() +
                          userData?.gender?.slice(1)}
                      </h6>
                    </div>
                  </div>
                  <div className="px-2  px-sm-3 px-lg-4 px-xl-5">
                    <div className="d-flex gap-1">
                      <img
                        src={calender}
                        className="basic-detail-icon"
                        alt=""
                      />
                      <div>
                        <h6 className="mb-1">Date of Birth</h6>
                        <h6 className="text-light">{userData?.dob}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-1">
                    <img src={matesIcon} className="basic-detail-icon" alt="" />
                    <div>
                      <h6 className="mb-1">Marital Status</h6>
                      <h6 className="text-light">
                        {userData?.marital_status
                          ? userData.marital_status.charAt(0).toUpperCase() +
                            userData.marital_status.slice(1)
                          : "N/A"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-education mt-4 rounded-3 bg-white py-4">
              <div className="d-flex flex-wrap justify-content-between gap-3">
                <span className="profile-tag ps-2 ps-sm-3 ps-lg-4 ps-xl-5 py-1">
                  EDUCATIONAL
                </span>

                <span
                  style={{
                    background: "#80808040",
                    backdropFilter: "blur(10px)",
                    lineHeight: "2",
                    fontWeight: "500",
                  }}
                  className="py-1 px-2 rounded m-0 me-4 ms-2 small"
                >
                  Highest Qualification:{" "}
                  {user
                    ? state?.user?.highest_qualification
                    : userData?.highest_qualification}
                </span>
              </div>

              <div className="mt-4 px-2  px-sm-3 px-lg-4 px-xl-5">
                {user ? (
                  education?.length > 0 ? (
                    education
                      ?.filter((e) => e?.status !== 0)
                      .map((e) => (
                        <Todo
                          key={e.id}
                          setShow={setShow}
                          setEdit={() => setEdit(e)}
                          user={user}
                          setImageModal={setImageModal}
                          degree={process.env.REACT_APP_API_URL + e?.file}
                          setDegree={setDegree}
                          image={degreeCap}
                          status={e?.status}
                          degreeName={e?.degree}
                          institute={e?.institute_name}
                          duration={e?.duration}
                          start={e.start_year}
                          end={e?.end_year}
                          heading="Education"
                        />
                      ))
                  ) : (
                    <p>No Education Added</p>
                  )
                ) : education?.length > 0 ? (
                  education
                    ?.filter((e) => e?.status !== 0)
                    .map((e) => (
                      <Todo
                        key={e.id}
                        setShow={setShow}
                        setEdit={() => setEdit(e)}
                        user={user}
                        setImageModal={setImageModal}
                        degree={process.env.REACT_APP_API_URL + e?.file}
                        setDegree={setDegree}
                        image={degreeCap}
                        status={e?.status}
                        degreeName={e?.degree}
                        institute={e?.institute_name}
                        duration={e?.duration}
                        start={e.start_year}
                        end={e?.end_year}
                        heading="Education"
                      />
                    ))
                ) : (
                  <p>No Education Added</p>
                )}
                {user == true && (
                  <button
                    onClick={() => {
                      setShow(true);
                    }}
                    className="btn experience-btn-more gap-1 py-1 align-items-center px-0"
                  >
                    <div className="add-icon ms-1 d-flex justify-content-center align-items-center">
                      <span className="fa-solid fa-plus text-small"></span>
                    </div>
                    <span className="text-smallest font-600">Add more</span>
                  </button>
                )}
              </div>
            </div>
            <div className="profile-experience mt-4 rounded-3 bg-white pt-4 pb-5">
              <span className="profile-tag ps-2 ps-sm-3 ps-lg-4 ps-xl-5 py-1 mt-23">
                EXPERIENCE
              </span>
              <div className="mt-4 px-2  px-sm-3 px-lg-4 px-xl-5">
                <h6 className="job-proof mb-3">Job Proof</h6>

                {user ? (
                  profession?.length > 0 ? (
                    profession
                      ?.filter((e) => e?.type !== "business" && e?.status !== 0)
                      .sort((a, b) => {
                        if (a?.type === "current" && b?.type !== "current")
                          return -1;
                        if (a?.type !== "current" && b?.type === "current")
                          return 1;
                        if (a?.type === "past" && b?.type !== "past") return -1;
                        if (a?.type !== "past" && b?.type === "past") return 1;
                        return 0;
                      })
                      .map((e) => {
                        let duration;

                        if (!e?.end_year) {
                          const currentYear = new Date().getFullYear();
                          duration = currentYear - e?.start_year;
                        } else {
                          duration = e?.end_year - e?.start_year;
                        }

                        return (
                          <Todo2
                            setShow2={setShow2}
                            key={e?.id}
                            setEdit={() => setEdit(e)}
                            user={user}
                            setImageModal={setImageModal}
                            image={briefCaseIcon}
                            degree={process.env.REACT_APP_API_URL + e?.file}
                            setDegree={setDegree}
                            role={e?.role}
                            companyName={e?.company_name}
                            start={e?.start_year}
                            end={e?.end_year}
                            type={e?.type}
                            show={setShow2}
                            duration={duration}
                            heading={e?.job_title}
                            status={e?.status}
                          />
                        );
                      })
                  ) : (
                    <p>No job experience added</p>
                  )
                ) : profession?.length > 0 ? (
                  profession
                    ?.filter((e) => e?.type !== "business" && e?.status != 0)
                    .sort((a, b) => {
                      if (a?.type === "current" && b?.type !== "current")
                        return -1;
                      if (a?.type !== "current" && b?.type === "current")
                        return 1;
                      if (a?.type === "past" && b?.type !== "past") return -1;
                      if (a?.type !== "past" && b?.type === "past") return 1;
                      return 0;
                    })
                    .map((e) => {
                      let duration;

                      if (!e?.end_year) {
                        const currentYear = new Date().getFullYear();
                        duration = currentYear - e?.start_year;
                      } else {
                        duration = e?.end_year - e?.start_year;
                      }

                      return (
                        <Todo2
                          setShow2={setShow2}
                          key={e?.id}
                          setEdit={() => setEdit(e)}
                          user={user}
                          setImageModal={setImageModal}
                          image={briefCaseIcon}
                          degree={process.env.REACT_APP_API_URL + e?.file}
                          setDegree={setDegree}
                          role={e?.role}
                          companyName={e?.company_name}
                          start={e?.start_year}
                          end={e?.end_year}
                          type={e?.type}
                          show={setShow2}
                          duration={duration}
                          heading={e?.job_title}
                          status={e?.status}
                        />
                      );
                    })
                ) : (
                  <p>No job experience added</p>
                )}

                {user == true && (
                  <button
                    onClick={() => {
                      setShow2(true);
                    }}
                    className="btn experience-btn-more gap-1 py-1 align-items-center px-0"
                  >
                    <div className="add-icon ms-1 d-flex justify-content-center align-items-center">
                      <span className="fa-solid fa-plus text-small"></span>
                    </div>
                    <span className="text-smallest font-600">Add more</span>
                  </button>
                )}
              </div>
              <div className="mt-4 px-2 px-sm-3 px-lg-4 px-xl-5">
                <h6 className="job-proof mb-3">Business Proof</h6>

                {user ? (
                  profession?.length > 0 ? (
                    profession
                      ?.filter((e) => e?.type === "business" && e?.status !== 0) // Filter for business type and status not 0
                      .map((e) => {
                        let duration;

                        if (!e?.end_year) {
                          const currentYear = new Date().getFullYear();
                          duration = currentYear - e?.start_year;
                        } else {
                          duration = e?.end_year - e?.start_year;
                        }

                        return (
                          <Todo3
                            setShow3={setShow3}
                            setEdit={() => setEdit(e)}
                            key={e?.id}
                            user={user}
                            setImageModal={setImageModal}
                            image={briefCaseIcon}
                            degree={process.env.REACT_APP_API_URL + e?.file}
                            setDegree={setDegree}
                            type={e?.type}
                            role={e?.role}
                            companyName={e?.company_name}
                            start={e?.start_year}
                            end={e?.end_year}
                            show={setShow2}
                            duration={duration}
                            status={e?.status}
                          />
                        );
                      })
                  ) : (
                    <p>No Businesses</p>
                  )
                ) : profession?.length > 0 ? (
                  profession
                    ?.filter((e) => e?.type === "business" && e?.status != 0) // Filter for business type and status 1
                    .map((e) => {
                      let duration;

                      if (!e?.end_year) {
                        const currentYear = new Date().getFullYear();
                        duration = currentYear - e?.start_year;
                      } else {
                        duration = e?.end_year - e?.start_year;
                      }

                      return (
                        e.status !== 0 && (
                          <Todo3
                            setShow3={setShow3}
                            setEdit={() => setEdit(e)}
                            key={e?.id}
                            user={user}
                            setImageModal={setImageModal}
                            image={briefCaseIcon}
                            degree={process.env.REACT_APP_API_URL + e?.file}
                            setDegree={setDegree}
                            type={e?.type}
                            role={e?.role}
                            companyName={e?.company_name}
                            start={e?.start_year}
                            end={e?.end_year}
                            show={setShow2}
                            duration={duration}
                            status={e?.status}
                          />
                        )
                      );
                    })
                ) : (
                  <p>No Businesses</p>
                )}

                {user == true && (
                  <button
                    onClick={() => {
                      setShow3(true);
                    }}
                    className="btn experience-btn-more gap-1 py-1 align-items-center px-0"
                  >
                    <div className="add-icon ms-1 d-flex justify-content-center align-items-center">
                      <span className="fa-solid fa-plus text-small"></span>
                    </div>
                    <span className="text-smallest font-600">Add more</span>
                  </button>
                )}
              </div>
            </div>
            <div className="profile-experience mt-4 rounded-3 bg-white pt-4 pb-4">
              <span className="profile-tag ps-2 ps-sm-3 ps-lg-4 ps-xl-5 py-1 mt-23">
                Personal
              </span>
              <div className="mt-4 px-2 px-sm-3 px-lg-4 px-xl-5">
                <h6 className="job-proof mb-3">Personal Proof</h6>
                {user ? (
                  <Todo4
                    user={user}
                    image={briefCaseIcon}
                    setShow4={setShow4}
                    status={document?.status}
                  />
                ) : (
                  ""
                )}
                {!user ? (
                  document?.status == 1 ? (
                    <Todo4
                      user={user}
                      image={briefCaseIcon}
                      setShow4={setShow4}
                      status={document?.status}
                    />
                  ) : (
                    <p>No governement document is added</p>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="hobbies bg-white mt-4 mb-4 mb-md-0 rounded-3 px-2  px-sm-3 px-lg-4 px-xl-5 py-4">
              <h5 className="font-600 mb-4">Hobbies</h5>
              <HobbiesAccordion
                questions={questions}
                user={user}
                state={state}
                userData={userData}
              />
            </div>
          </div>
          <div className="col-md-5 col-lg-4 col-xl-3">
            <Passions />
            <UserInfo userData={userData} />
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;

function Todo({
  degreeName,
  degree,
  institute,
  status = 2,
  start,
  end = "",
  duration,
  image,
  user,
  setImageModal,
  setDegree,
  setEdit,
  setShow,
  heading,
}) {
  return (
    <div className="d-flex flex-column flex-sm-row mb-4 justify-content-sm-between align-items-sm-center">
      <div className="d-flex gap-2">
        <div className="education-icon p-1 rounded-1">
          <img src={image} alt="" />
        </div>
        <div>
          <h6 className="mb-1 small font-700">{heading}</h6>
          <h6 className="text-small font-600 mb-1">
            {degreeName || "N/A"} ({duration || "N/A"} years)
          </h6>
          <p className="text-smallest mb-0 text-light">{institute}</p>
          <p className="text-smallest mb-0 text-light">
            {start || "N/A"} {end && `- ${end}`}
          </p>
        </div>
      </div>
      {status && (
        <div className="approval d-flex mt-3 mt-sm-0 gap-3 align-items-center justify-content-end">
          <div className="approval d-flex mt-0 gap-3 align-items-center justify-content-end">
            <div className="p-0 m-0">
              {status == 1 ? (
                <div className=" approved py-1 px-3">Approved</div>
              ) : user ? (
                <div className=" py-1 px-2 text-warning text-small bg-warning bg-opacity-25 rounded-5">
                  Pending
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div onClick={() => setImageModal(true)}>
            <img
              className="search-eye-icon pointer"
              src={serachInEye}
              alt=""
              onClick={() => {
                if (setDegree) {
                  setDegree(degree);
                }
                setImageModal(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
function Todo2({
  role,
  companyName,
  status = 2,
  start,
  end = "",
  type,
  duration,
  image,
  user,
  setImageModal,
  setDegree,
  degree,
  setEdit,
  setShow2,
  heading,
}) {
  return (
    <div className="d-flex flex-column flex-sm-row mb-4 justify-content-sm-between align-items-sm-center">
      <div className="d-flex gap-2">
        <div className="education-icon p-1 rounded-1">
          <img src={image} alt="" />
        </div>
        <div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="mb-1 small font-700">{heading}</h6>
            {type === "current" && (
              <p className="text-white rounded-5 py-1 px-2 text-center current">
                Current
              </p>
            )}
          </div>
          <h6 className="text-small font-600 mb-1">
            {role?.charAt(0)?.toUpperCase() + role?.slice(1)} ({duration} years)
          </h6>
          <p className="text-smallest mb-0 text-light">{companyName}</p>
          <p className="text-smallest mb-0 text-light">
            {start} {end && `- ${end}`}
          </p>
        </div>
      </div>
      {status ? (
        <div className="approval d-flex mt-3 mt-sm-0 gap-3 algin-items-center justify-content-end">
          <div className="approval d-flex mt-0 gap-3 algin-items-center justify-content-end">
            <div>
              {status == 1 ? (
                <div className="approved py-1 px-3">Approved</div>
              ) : (
                user && (
                  <div className=" py-1 px-2 text-warning text-small bg-warning bg-opacity-25 rounded-5">
                    Pending
                  </div>
                )
              )}
            </div>
          </div>
          <div onClick={() => setImageModal(true)}>
            <img
              className="search-eye-icon pointer"
              src={serachInEye}
              alt=""
              onClick={() => {
                if (setDegree) {
                  setDegree(degree);
                }
                setImageModal(true);
              }}
            />
          </div>
          {user && (
            <div>
              <img
                className="edit-icon"
                src={editLogo}
                alt=""
                onClick={() => {
                  setShow2(true);
                  setEdit();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        user && (
          <div
            onClick={() => {
              setShow2(true);
            }}
            className="add-icon d-flex ms-auto mt-3 mt-sm-0 justify-content-center align-items-center"
          >
            <span className="fa-solid fa-plus small"></span>
          </div>
        )
      )}
    </div>
  );
}
function Todo3({
  role,
  companyName,
  status = 2,
  start,
  end = "",
  type,
  duration,
  image,
  user,
  setImageModal,
  setDegree,
  degree,
  setEdit,
  setShow3,
  heading,
}) {
  return (
    <div className="d-flex flex-column flex-sm-row mb-4 justify-content-sm-between align-items-sm-center">
      <div className="d-flex gap-2">
        <div className="education-icon p-1 rounded-1">
          <img src={image} alt="" />
        </div>
        <div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="mb-1 small font-700">{heading}</h6>
            {type === "current" && (
              <p className="text-white rounded-5 py-1 px-2 text-center current">
                Current
              </p>
            )}
          </div>
          <h6 className="text-small font-600 mb-1">
            {role} ({duration} years)
          </h6>
          <p className="text-smallest mb-0 text-light">{companyName}</p>
          <p className="text-smallest mb-0 text-light">
            {start} {end && `- ${end}`}
          </p>
        </div>
      </div>
      {status ? (
        <div className="approval d-flex mt-3 mt-sm-0 gap-3 algin-items-center justify-content-end">
          <div className="approval d-flex mt-0 gap-3 algin-items-center justify-content-end">
            <div>
              {status == 1 ? (
                <div className="approved py-1 px-3">Approved</div>
              ) : (
                user && (
                  <div className=" py-1 px-2 text-warning text-small bg-warning bg-opacity-25 rounded-5">
                    Pending
                  </div>
                )
              )}
            </div>
          </div>
          <div onClick={() => setImageModal(true)}>
            <img
              className="search-eye-icon pointer"
              src={serachInEye}
              alt=""
              onClick={() => {
                if (setDegree) {
                  setDegree(degree);
                }
                setImageModal(true);
              }}
            />
          </div>
          {user && (
            <div>
              <img
                className="edit-icon"
                src={editLogo}
                alt=""
                onClick={() => {
                  setShow3(true);
                  setEdit();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={() => {
            setShow3(true);
          }}
          className="add-icon d-flex ms-auto mt-3 mt-sm-0 justify-content-center align-items-center"
        >
          <span className="fa-solid fa-plus small"></span>
        </div>
      )}
    </div>
  );
}
function Todo4({ status = "", image, user, setShow4 }) {
  return (
    <div className="d-flex flex-column flex-sm-row mb-4 justify-content-sm-between align-items-sm-center">
      <div className="d-flex gap-2">
        <div className="education-icon p-1 rounded-1">
          <img src={image} alt="" />
        </div>
        <div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="mb-1 small font-700">Government issue document</h6>
          </div>
        </div>
      </div>
      {status != "" ? (
        <div className="approval d-flex mt-3 mt-sm-0 gap-3 algin-items-center justify-content-end">
          <div className="approval d-flex mt-0 gap-3 algin-items-center justify-content-end">
            <div>
              {status == 1 ? (
                <div className="approved py-1 px-3">Evidence Accepted</div>
              ) : (
                <div className=" py-1 px-2 text-warning text-small bg-warning bg-opacity-25 rounded-5">
                  Pending
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        user && (
          <div
            onClick={() => {
              setShow4(true);
            }}
            className="add-icon d-flex ms-auto mt-3 mt-sm-0 justify-content-center align-items-center"
          >
            <span className="fa-solid fa-plus small"></span>
          </div>
        )
      )}
    </div>
  );
}

function UserInfo({ userData }) {
  return (
    <div className="mt-4 p-3 bg-white rounded-3 user-info">
      <h5 className="mb-4">About</h5>
      <h6 className="mb-1 font-600">Native Country</h6>
      <p className="text-light small">{userData?.native_country || "N/A"}</p>
      <h6 className="mb-1 font-600">Native City</h6>
      <p className="text-light small">{userData?.native_city || "N/A"}</p>
      <h6 className="mb-1 font-600">Country of Residence</h6>
      <p className="text-light small">{userData?.country_of_residence}</p>
      <h6 className="mb-1 font-600">City of Residence</h6>
      <p className="text-light small">{userData?.city_of_residence || "N/A"}</p>
      <h6 className="mb-1 font-600">Religion</h6>
      <p className="text-light small">{userData?.religion || "N/A"}</p>
      <h6 className="mb-1 font-600">Ethnicty</h6>
      <p className="text-light small">{userData?.ethnicity || "N/A"}</p>
      <h6 className="mb-1 font-600">Sect</h6>
      <p className="text-light small">{userData?.sect || "N/A"}</p>
      <h6 className="mb-1 font-600">Cast</h6>
      <p className="text-light small">{userData?.cast || "N/A"}</p>
      <h6 className="mb-1 font-600">Height</h6>
      <p className="text-light small mb-5">{userData?.height || "N/A"}</p>
      <h6 className="mb-1 font-600">Highest Qualification</h6>
      <p className="text-light small">
        {userData?.highest_qualification || "N/A"}
      </p>
      <h6 className="mb-1 font-600">Profession</h6>
      <p className="text-light small">{userData?.profession || "N/A"}</p>
    </div>
  );
}

function HobbiesAccordion({ user, questions, state }) {
  const [allQuestions, setAllQuestions] = useState([]);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const newQuestions = questions?.filter((e) => {
      return e?.answer != null;
    });

    setAllQuestions(newQuestions);
  }, [questions, user, state]);

  return (
    <div>
      {allQuestions?.length > 0 ? (
        allQuestions?.map((e, index) => {
          return (
            <HobbyAcccordion
              key={index}
              setCurrent={setCurrent}
              question={e?.question?.question}
              answer={e?.answer}
              answersArray={e?.question?.answer}
              user={user}
              current={current}
              index={index}
              quesObj={e?.question}
              state={state}
            />
          );
        })
      ) : (
        <div>No questions</div>
      )}
    </div>
  );
}

function HobbyAcccordion({
  setCurrent,
  current,
  index,
  user,
  question,
  answer,
  answersArray,
  quesObj,
  state,
}) {
  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const [editAns, setEditAns] = useState(false);
  const ref = useRef();
  let style1 = {
    height: "0px",
  };
  let style2 = {
    height: "35px",
  };
  useEffect(() => {
    if (current == index) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [current]);

  const [newAnswer, setNewAnswer] = useState(answer || "");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    const answer = newAnswer;
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}api/add/answer/${state?.user?.id}/${quesObj.id}`,
        { answer },
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.message == "Answer Saved Successfully") {
          toast.success("Answer Saved Successfully");
          setEditAns(false);
        }
      })
      .catch((err) => {
        toast.error("There is something went wrong!");
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setEditAns(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);

  return (
    <div className="mb-2">
      <div className="hobby-accordion">
        <div
          className="hobby-accordion-header pb-1 pointer d-flex gap-3 justify-content-between align-items-center"
          onClick={() => {
            setShow(!show);
            setCurrent(current !== index ? index : -1);
          }}
        >
          <p className="mb-0 text-light small font-500">{question}</p>
          <span
            className="fa-solid fa-angle-down angle-down"
            style={{ rotate: `${show ? "180deg" : "0deg"}` }}
          ></span>
        </div>
        <div
          className="hobby-accordion-body mb-0 d-flex mt-2 justify-content-between align-items-center gap-3"
          ref={ref}
          style={show ? style2 : style1}
        >
          {editAns ? (
            <form className="w-100" ref={formRef} onKeyDown={handleKeyDown}>
              <select
                value={newAnswer}
                className="question-dropdown text-small w-100 "
                onChange={(e) => {
                  setNewAnswer(e.target.value);
                  handleSubmit();
                }}
                name="answer"
              >
                <option value="" selected defaultValue>
                  Select
                </option>
                {answersArray?.map((e, index) => (
                  <option value={e?.answer} key={index}>
                    {e?.answer?.charAt(0).toUpperCase() + e?.answer?.slice(1)}
                  </option>
                ))}
              </select>
            </form>
          ) : (
            <p className="mb-0 text-small font-600">
              {newAnswer?.charAt(0).toUpperCase() + newAnswer?.slice(1)}
            </p>
          )}

          {user && (
            <img
              src={editLogo}
              style={{ height: "15px" }}
              alt=""
              onClick={() => {
                if (editAns == true) {
                  setEditAns(false);
                } else {
                  setEditAns(true);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ModalBody({
  heading,
  state,
  setEducation,
  setShow,
  edit,
  userQualification,
}) {
  const LIBRARIES = process.env.LIBRARIES
    ? process.env.LIBRARIES.split(",")
    : ["places"];

  const [autocomplete, setAutoComplete] = useState(null);

  const [btnDisable, setBtnDisable] = useState(false);
  const [ed_data, setEd_data] = useState({
    degreeName: edit?.degree || "",
    instituteName: edit?.institute_name || "",
    startYear: edit?.start_year || "",
    endYear: edit?.end_year || "",
    file: edit?.file ? process.env.REACT_APP_API_URL + edit.file : "",
    type: edit?.type,
  });

  const [error, setError] = useState({
    degreeName: "",
    instituteName: "",
    startYear: "",
    endYear: "",
  });

  const inputRef = {
    degreeName: useRef(),
    instituteName: useRef(),
    startYear: useRef(),
  };

  const handlePlaceSelect = async () => {
    if (autocomplete) {
      const place = await autocomplete.getPlace();
      // Check if a place was selected and extract the relevant details

      if (place) {
        setEd_data((prev) => ({
          ...prev,
          instituteName: place?.name, // Update state properly
        }));
      }
    }
  };

  const onChange = (e) => {
    const { name, type } = e.target;

    if (e.target.value) {
      setError((prev) => ({
        ...prev,
        [e.target.name]: "",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [e.target.name]: "required",
      }));
    }

    if (type === "file") {
      const file = e.target.files[0];
      setEd_data((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else {
      const value = e.target.value;
      setEd_data((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const debouncedFormSubmit = debounce(
    async (formData, url, headers, callback) => {
      try {
        const response = await axios.post(url, formData, { headers });
        callback(response);
      } catch (err) {
        if (err?.message === "Network Error" && state?.login === true) {
          toast.error("Check your internet Connection");
        } else {
          toast.error("There is something went wrong!");
        }
        setBtnDisable(false);
      }
    },
    1000
  );

  const formSubmit = async () => {
    let firstErrorField = null;
    let hasError = null;

    const newErrors = { ...error };
    Object.keys(ed_data).forEach((key) => {
      if (key != "file" && ed_data[key] === "") {
        newErrors[key] = "required";
        if (firstErrorField === null) {
          firstErrorField = key;
        }
        hasError = true;
      } else {
        newErrors[key] = ""; // Clear any previous error
      }
    });

    setError(newErrors);

    if (firstErrorField) {
      inputRef[firstErrorField]?.current?.focus();
      inputRef[firstErrorField]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
      return; // Stop form submission if there's an error
    }

    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => value === "");
    };

    if (areAllErrorsEmpty(newErrors)) {
      const formData = new FormData();
      formData.append("institute_name", ed_data.instituteName);
      formData.append("end_year", ed_data.endYear);
      formData.append("start_year", ed_data.startYear);
      formData.append("type", ed_data.type);
      formData.append("degree", ed_data.degreeName);
      if (typeof ed_data.file !== "string") {
        formData.append("file", ed_data.file); // Assuming ed_data.file is a file object
      }
      formData.append("user_id", state?.user?.id);

      setBtnDisable(true);

      const url = edit?.id
        ? `${process.env.REACT_APP_API_URL}api/update/education/${edit?.id}`
        : `${process.env.REACT_APP_API_URL}api/add/education`;

      const headers = {
        Authorization: `Bearer ${state?.token}`,
        "Content-Type": "multipart/form-data", // Important for file upload
      };

      debouncedFormSubmit(formData, url, headers, (response) => {
        setEducation((prev) => {
          return edit?.id
            ? prev.map((e) => (e.id === edit.id ? response.data.data : e))
            : [...prev, response.data.degree];
        });

        toast.success(response?.data?.message);
        if (
          response?.data?.message ===
          (edit?.id
            ? "data updated successfully"
            : "Education added successfully")
        ) {
          setShow(false);
          setBtnDisable(false);
        }
      });
    }
  };

  return (
    <>
      <div className="px-5 py-4">
        <h5 className="text-center font-600 mt-2 mb-4">{heading}</h5>
        <div className="form-group mb-4">
          <label className="mb-2 label">Degree Name</label>

          <select
            className="form-select"
            value={ed_data.degreeName}
            onChange={onChange}
            name="degreeName"
          >
            <option value="" disabled>
              Select Your Degree
            </option>

            {userQualification
              // Filter out duplicate categories by using the unique `id` of each category.
              .filter(
                (category, index, self) =>
                  index === self.findIndex((cat) => cat.id === category.id)
              )
              .map((category) => (
                <optgroup
                  key={category.id}
                  label={category.name}
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {category.degree.map((degreeItem) => (
                    <option key={degreeItem.id} value={degreeItem.degree}>
                      {degreeItem.degree}
                    </option>
                  ))}
                </optgroup>
              ))}
          </select>

          {error.degreeName && (
            <p className="small mb-0 text-danger mt-1">
              Degree name is required
            </p>
          )}
        </div>
        <div className="form-group mb-4 z-4">
          <label className="mb-2 label">Institute Name</label>
          <LoadScript
            googleMapsApiKey="AIzaSyDG7XfkORzutadfHB9UjySVsmMlH9ODD3Q"
            libraries={LIBRARIES}
          >
            <Autocomplete
              onLoad={(autocompleteInstance) => {
                setAutoComplete(autocompleteInstance);
              }}
              onPlaceChanged={handlePlaceSelect}
              options={{
                types: ["establishment"], // Restrict to establishments (includes educational institutes)
              }}
            >
              <input
                type="text"
                name="instituteName"
                placeholder="Enter institute name"
                className="form-control"
                value={ed_data.instituteName}
                onChange={onChange} // Keep this line for state updates
              />
            </Autocomplete>
          </LoadScript>

          {error.instituteName && (
            <p className="small mb-0 text-danger mt-1">
              Institute name is required
            </p>
          )}
        </div>
        <div className="row">
          <div className="col-6 mb-4">
            <div className="form-group mb-4">
              <label className="mb-2 label">
                Start{" "}
                <span className="text-light">
                  (<span className="text-small">Year</span> )
                </span>
              </label>
              <select
                value={ed_data.startYear}
                className="form-select"
                onChange={onChange}
                name="startYear"
                ref={inputRef.startYear}
              >
                <option value="" disabled defaultValue>
                  Select
                </option>
                {Array.from(
                  { length: new Date().getFullYear() - 1980 + 1 },
                  (_, index) => {
                    const year = 1980 + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                )}
              </select>
              {error.startYear && (
                <p className="small mb-0 text-danger mt-1">
                  Start year is required
                </p>
              )}
            </div>
          </div>
          <div className="col-6 mb-4">
            <div className="form-group mb-4">
              <label className="mb-2 label">
                End{" "}
                <span className="text-light">
                  (<span className="text-small">Year</span> )
                </span>
              </label>
              <select
                value={ed_data.endYear}
                onChange={onChange} // Add this line
                className="form-select"
                name="endYear" // Add name to the select
              >
                <option value="" disabled defaultValue>
                  Select
                </option>
                {Array.from(
                  { length: new Date().getFullYear() - 1980 + 1 },
                  (_, index) => {
                    const year = 1980 + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                )}
              </select>
              {error.endYear && (
                <p className="small mb-0 text-danger mt-1">
                  End year is required
                </p>
              )}
            </div>
          </div>
        </div>
        <SelectFile
          ref={inputRef.file}
          file={ed_data.file}
          setData={setEd_data}
          onChange={onChange}
        />
        {error.file && (
          <p className="small mb-0 text-danger mt-1">Document is required</p>
        )}
      </div>
      <div className="text-center pb-5">
        <button
          disabled={btnDisable}
          onClick={formSubmit}
          className="btn auth-btn clr-btn-theme px-5 mx-auto"
        >
          {btnDisable ? (
            <Loading stroke="4" width="20" height="20" />
          ) : edit?.id ? (
            "Update"
          ) : (
            "Add"
          )}
        </button>
      </div>
    </>
  );
}

function ModalBody2({
  buttonText,
  state,
  setExperince,
  setShow2,
  edit,
  professions,
}) {
  const LIBRARIES = process.env.LIBRARIES
    ? process.env.LIBRARIES.split(",")
    : ["places"];

  const [autocomplete, setAutoComplete] = useState(null);

  const [btnDisable, setBtnDisable] = useState(false);
  const [ex_data, setEx_data] = useState({
    type: edit?.type || "past",
    jobTitle: edit?.job_title || "",
    companyName: edit?.company_name || "",
    role: edit?.role || "",
    endYear: edit?.end_year || "",
    startYear: edit?.start_year || "",
    file: edit?.file ? process.env.REACT_APP_API_URL + edit.file : "",
  });

  const [error, setError] = useState({
    jobTitle: "",
    companyName: "",
    role: "",
    startYear: "",
  });
  const inputRef = {
    jobTitle: useRef(),
    companyName: useRef(),
    role: useRef(),
    startYear: useRef(),
  };

  const onChange = (e) => {
    const { name, type, checked, value, files } = e.target;

    // Handle checkbox for "current" job
    if (type === "checkbox") {
      setEx_data((prevData) => ({
        ...prevData,
        type: checked ? "current" : "past",
      }));
    } else if (type === "file") {
      // Handle file input
      const file = files[0];
      setEx_data((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      // Handle text inputs and selects
      setEx_data((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Clear error if input is valid
    if (value) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [name]: "required",
      }));
    }
  };

  const handlePlaceSelect = async () => {
    if (autocomplete) {
      const place = await autocomplete.getPlace();
      // Check if a place was selected and extract the relevant details

      if (place) {
        setEx_data((prev) => ({
          ...prev,
          companyName: place?.name,
        }));
      }
    }
  };

  const debouncedFormSubmit = debounce(
    async (formData, url, headers, callback) => {
      try {
        const response = await axios.post(url, formData, { headers });
        callback(response);
      } catch (err) {
        if (err?.message === "Network Error" && state?.login === true) {
          toast.error("Check your internet Connection");
        } else {
          toast.error("There is something went wrong!");
        }
        setBtnDisable(false);
      }
    },
    1000
  );

  const formSubmit = async () => {
    let firstErrorField = null;
    let hasError = null;

    // Validate form data and set errors
    const newErrors = { ...error };
    Object.keys(ex_data).forEach((key) => {
      // Skip validation for endYear and type
      if (key === "endYear" || key === "type" || key == "file") {
        newErrors[key] = ""; // Clear any previous error for endYear and type
        return;
      }

      if (ex_data[key] === "") {
        newErrors[key] = "required";
        if (firstErrorField === null) {
          firstErrorField = key;
        }
        hasError = true;
      } else {
        newErrors[key] = ""; // Clear any previous error
      }
    });

    // Update the error state

    setError(newErrors);

    // Focus and scroll to the first error field
    if (firstErrorField) {
      inputRef[firstErrorField]?.current?.focus();
      inputRef[firstErrorField]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
      return; // Stop form submission if there's an error
    }

    // Check if there are no errors
    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "object" && value !== null) {
          return areAllErrorsEmpty(value);
        }
        return value === "";
      });
    };

    if (areAllErrorsEmpty(newErrors)) {
      const formData = new FormData();
      formData.append("company_name", ex_data.companyName);
      formData.append("job_title", ex_data.jobTitle);
      formData.append("end_year", ex_data.endYear);
      formData.append("start_year", ex_data.startYear);
      formData.append("type", ex_data.type);
      formData.append("role", ex_data.role);
      if (typeof ex_data.file !== "string") {
        formData.append("file", ex_data.file); // Assuming ed_data.file is a file object
      }
      formData.append("user_id", state?.user?.id);

      setBtnDisable(true);

      const url = edit?.id
        ? `${process.env.REACT_APP_API_URL}api/update/profession/${edit?.id}`
        : `${process.env.REACT_APP_API_URL}api/add/profession`;

      const headers = {
        Authorization: `Bearer ${state?.token}`,
        "Content-Type": "multipart/form-data", // Important for file upload
      };

      debouncedFormSubmit(formData, url, headers, (response) => {
        if (edit?.id) {
          setExperince((prev) => {
            return prev.map((e) => {
              if (e.id === edit.id) {
                return response.data.data;
              }
              return e;
            });
          });
          toast.success("Data updated successfully");
          setShow2(false);
          setBtnDisable(false);
          return;
        }

        setExperince((prev) => {
          return [...prev, response.data.degree];
        });
        toast.success("Profession Added Successfully");
        setShow2(false);
        setBtnDisable(false);
      });
    }
  };

  return (
    <>
      <div className="px-5 py-4">
        <h5 className="text-center font-600 mt-2 mb-4">
          Add Your Job Preference
        </h5>
        <div className="form-group mb-4">
          <label className="mb-2 label">Job Title</label>

          <select
            className="form-select"
            value={ex_data.jobTitle}
            onChange={onChange}
            ref={inputRef.jobTitle}
            name="jobTitle"
          >
            <option value="" disabled>
              Select Your Profession
            </option>

            {professions
              .filter((profession) => profession.status === "1") // Filtering active professions with status '1'
              .map((profession) => (
                <option key={profession.id} value={profession.name}>
                  {profession.name}
                </option>
              ))}
          </select>
          {error.jobTitle && (
            <p className="small mb-0 text-danger mt-1">Job Title is required</p>
          )}
        </div>
        <div className="form-group mb-4">
          <label className="mb-2 label">Role</label>
          <select
            value={ex_data.role}
            className="form-select"
            onChange={onChange}
            name="role"
            ref={inputRef.role}
          >
            <option value="" disabled defaultValue>
              Select
            </option>
            <option value="senior">Senior</option>
            <option value="junior">Junior</option>
            <option value="internee">Internee</option>
          </select>
          {error.role && (
            <p className="small mb-0 text-danger mt-1">Role is required</p>
          )}
        </div>

        <div className="form-group mb-4">
          <label className="mb-2 label">Company Name</label>
          <LoadScript
            googleMapsApiKey="AIzaSyDG7XfkORzutadfHB9UjySVsmMlH9ODD3Q"
            libraries={LIBRARIES}
          >
            <Autocomplete
              onLoad={(autocompleteInstance) => {
                setAutoComplete(autocompleteInstance);
              }}
              onPlaceChanged={handlePlaceSelect}
              options={{
                types: ["establishment"], // Restrict to establishments (includes educational institutes)
              }}
            >
              <input
                type="text"
                name="companyName"
                placeholder="Enter company name"
                className="form-control"
                value={ex_data.companyName}
                ref={inputRef.companyName}
                onChange={onChange} // Keep this line for state updates
              />
            </Autocomplete>
          </LoadScript>

          {error.companyName && (
            <p className="small mb-0 text-danger mt-1">
              Company name is required
            </p>
          )}
        </div>
        <div className="row">
          <div className="col-6 mb-1">
            <div className="form-group mb-1">
              <label className="mb-2 label">
                Start{" "}
                <span className="text-light">
                  (<span className="text-small">Year</span> )
                </span>
              </label>
              <select
                value={ex_data.startYear}
                className="form-select"
                onChange={onChange}
                name="startYear"
                ref={inputRef.startYear}
              >
                <option value="" disabled defaultValue>
                  Select
                </option>
                {Array.from(
                  { length: new Date().getFullYear() - 1980 + 1 },
                  (_, index) => {
                    const year = 1980 + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                )}
              </select>
              {error.startYear && (
                <p className="small mb-0 text-danger mt-1">
                  Start year is required
                </p>
              )}
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="form-group mb-1">
              <label className="mb-2 label">
                End{" "}
                <span className="text-light">
                  (<span className="text-small">Year</span> )
                </span>
              </label>
              <select
                value={ex_data.endYear}
                onChange={onChange} // Add this line
                className="form-select"
                name="endYear" // Add name to the select
              >
                <option value="" disabled defaultValue>
                  Select
                </option>
                {Array.from(
                  { length: new Date().getFullYear() - 1980 + 1 },
                  (_, index) => {
                    const year = 1980 + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="mb-4 d-flex align-items-center gap-2">
          <input
            type="checkbox"
            name="current"
            value={ex_data.type}
            id="check"
            onChange={onChange}
            checked={ex_data.type === "current"}
          />
          <label htmlFor="check" className="text-small">
            Are you still doing job here
          </label>
        </div>
        <SelectFile
          ref={inputRef.file}
          file={ex_data.file}
          setData={setEx_data}
          onChange={onChange}
        />
        {error.file && (
          <p className="small mb-0 text-danger mt-1">Document is required</p>
        )}
      </div>
      <div className="text-center pb-5">
        <button
          disabled={btnDisable}
          onClick={() => {
            formSubmit();
          }}
          className="btn auth-btn clr-btn-theme px-5 mx-auto"
        >
          {btnDisable ? (
            <Loading stroke="4" width="20" height="20" />
          ) : edit?.id ? (
            "Update"
          ) : (
            "Add"
          )}
        </button>
      </div>
    </>
  );
}

function BussinessModel({ buttonText, state, setExperince, setShow3, edit }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [ex_data, setEx_data] = useState({
    type: edit?.type || "business",
    companyName: edit?.company_name || "",
    role: edit?.role || "",
    endYear: edit?.end_year || "",
    startYear: edit?.start_year || "",
    file: edit?.file ? process.env.REACT_APP_API_URL + edit.file : "",
  });

  const [error, setError] = useState({
    companyName: "",
    role: "",
    startYear: "",
  });
  const inputRef = {
    companyName: useRef(),
    role: useRef(),
    startYear: useRef(),
  };

  const onChange = (e) => {
    const { name, type, checked, value, files } = e.target;

    // Handle checkbox for "current" job
    if (type === "file") {
      // Handle file input
      const file = files[0];
      setEx_data((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      // Handle text inputs and selects
      setEx_data((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Clear error if input is valid
    if (value) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [name]: "required",
      }));
    }
  };

  const debouncedFormSubmit = debounce(
    async (formData, url, headers, callback) => {
      try {
        const response = await axios.post(url, formData, { headers });
        callback(response);
      } catch (err) {
        if (err?.message === "Network Error" && state?.login === true) {
          toast.error("Check your internet Connection");
        } else {
          toast.error("There is something went wrong!");
        }
        setBtnDisable(false);
      }
    },
    1000
  );

  const formSubmit = async () => {
    let firstErrorField = null;
    let hasError = null;

    // Validate form data and set errors
    const newErrors = { ...error };
    Object.keys(ex_data).forEach((key) => {
      // Skip validation for endYear and type
      if (key === "endYear" || key === "type" || key == "file") {
        newErrors[key] = ""; // Clear any previous error for endYear and type
        return;
      }

      if (ex_data[key] === "") {
        newErrors[key] = "required";
        if (firstErrorField === null) {
          firstErrorField = key;
        }
        hasError = true;
      } else {
        newErrors[key] = ""; // Clear any previous error
      }
    });

    // Update the error state

    setError(newErrors);

    // Focus and scroll to the first error field
    if (firstErrorField) {
      inputRef[firstErrorField]?.current?.focus();
      inputRef[firstErrorField]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
      return; // Stop form submission if there's an error
    }

    // Check if there are no errors
    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "object" && value !== null) {
          return areAllErrorsEmpty(value);
        }
        return value === "";
      });
    };

    if (areAllErrorsEmpty(newErrors)) {
      const formData = new FormData();
      formData.append("company_name", ex_data.companyName);

      formData.append("end_year", ex_data.endYear);
      formData.append("start_year", ex_data.startYear);
      formData.append("type", ex_data.type);
      formData.append("role", ex_data.role);
      if (typeof ex_data.file !== "string") {
        formData.append("file", ex_data.file); // Assuming ed_data.file is a file object
      }
      formData.append("user_id", state?.user?.id);

      setBtnDisable(true);

      const url = edit?.id
        ? `${process.env.REACT_APP_API_URL}api/update/profession/${edit?.id}`
        : `${process.env.REACT_APP_API_URL}api/add/profession`;

      const headers = {
        Authorization: `Bearer ${state?.token}`,
        "Content-Type": "multipart/form-data", // Important for file upload
      };

      debouncedFormSubmit(formData, url, headers, (response) => {
        if (edit?.id) {
          setExperince((prev) => {
            return prev.map((e) => {
              if (e.id === edit.id) {
                return response.data.data;
              }
              return e;
            });
          });
          toast.success("Data updated successfully");
          setShow3(false);
          setBtnDisable(false);
          return;
        }

        setExperince((prev) => {
          return [...prev, response.data.degree];
        });
        toast.success("Profession Added Successfully");
        setShow3(false);
        setBtnDisable(false);
      });
    }
  };

  return (
    <>
      <div className="px-5 py-4">
        <h5 className="text-center font-600 mt-2 mb-4">
          Add Your Job Preference
        </h5>
        <div className="form-group mb-4">
          <label className="mb-2 label">Job Title</label>
          <input
            type="text"
            name="role"
            placeholder="Enter your job title"
            className="form-control"
            value={ex_data.role}
            onChange={onChange}
            ref={inputRef.role}
          />
          {error.jobTitle && (
            <p className="small mb-0 text-danger mt-1">Role is required</p>
          )}
        </div>

        <div className="form-group mb-4">
          <label className="mb-2 label">Company Name</label>
          <input
            type="text"
            name="companyName"
            placeholder="Enter your company name"
            className="form-control"
            value={ex_data.companyName}
            onChange={onChange} // Add this line
            ref={inputRef.companyName}
          />
          {error.companyName && (
            <p className="small mb-0 text-danger mt-1">
              Company name is required
            </p>
          )}
        </div>
        <div className="row">
          <div className="col-6 mb-1">
            <div className="form-group mb-1">
              <label className="mb-2 label">
                Start{" "}
                <span className="text-light">
                  (<span className="text-small">Year</span> )
                </span>
              </label>
              <select
                value={ex_data.startYear}
                className="form-select"
                onChange={onChange}
                name="startYear"
                ref={inputRef.startYear}
              >
                <option value="" disabled defaultValue>
                  Select
                </option>
                {Array.from(
                  { length: new Date().getFullYear() - 1980 + 1 },
                  (_, index) => {
                    const year = 1980 + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                )}
              </select>
              {error.startYear && (
                <p className="small mb-0 text-danger mt-1">
                  Start year is required
                </p>
              )}
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="form-group mb-1">
              <label className="mb-2 label">
                End{" "}
                <span className="text-light">
                  (<span className="text-small">Year</span> )
                </span>
              </label>
              <select
                value={ex_data.endYear}
                onChange={onChange} // Add this line
                className="form-select"
                name="endYear" // Add name to the select
              >
                <option value="" disabled defaultValue>
                  Select
                </option>
                {Array.from(
                  { length: new Date().getFullYear() - 1980 + 1 },
                  (_, index) => {
                    const year = 1980 + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
        </div>

        <SelectFile
          ref={inputRef.file}
          file={ex_data.file}
          setData={setEx_data}
          onChange={onChange}
        />
        {error.file && (
          <p className="small mb-0 text-danger mt-1">Document is required</p>
        )}
      </div>
      <div className="text-center pb-5">
        <button
          disabled={btnDisable}
          onClick={() => {
            formSubmit();
          }}
          className="btn auth-btn clr-btn-theme px-5 mx-auto"
        >
          {btnDisable ? (
            <Loading stroke="4" width="20" height="20" />
          ) : edit ? (
            "Update"
          ) : (
            "Add"
          )}
        </button>
      </div>
    </>
  );
}

function DocumentModel({ state, setShow4 }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [doc, setDoc] = useState({
    file: "",
  });

  const [error, setError] = useState({
    file: "",
  });
  const inputRef = {
    file: useRef(),
  };

  const onChange = (e) => {
    const { name, type, value, files } = e.target;

    // Handle checkbox for "current" job
    if (type === "file") {
      // Handle file input
      const file = files[0];
      setDoc((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    }

    // Clear error if input is valid
    if (value) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [name]: "required",
      }));
    }
  };

  const debouncedFormSubmit = debounce(
    async (formData, url, headers, callback) => {
      try {
        const response = await axios.post(url, formData, { headers });
        callback(response);
      } catch (err) {
        if (err?.message === "Network Error" && state?.login === true) {
          toast.error("Check your internet Connection");
        } else {
          toast.error("There is something went wrong!");
        }
        setBtnDisable(false);
      }
    },
    1000
  );

  const formSubmit = async () => {
    let firstErrorField = null;
    let hasError = null;

    // Validate form data and set errors
    const newErrors = { ...error };
    Object.keys(setDoc).forEach((key) => {
      if (setDoc[key] === "") {
        newErrors[key] = "required";
        if (firstErrorField === null) {
          firstErrorField = key;
        }
        hasError = true;
      } else {
        newErrors[key] = ""; // Clear any previous error
      }
    });

    // Update the error state

    setError(newErrors);

    // Focus and scroll to the first error field
    if (firstErrorField) {
      inputRef[firstErrorField]?.current?.focus();
      inputRef[firstErrorField]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
      return; // Stop form submission if there's an error
    }

    // Check if there are no errors
    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "object" && value !== null) {
          return areAllErrorsEmpty(value);
        }
        return value === "";
      });
    };

    if (areAllErrorsEmpty(newErrors)) {
      const formData = new FormData();

      if (typeof doc.file !== "string") {
        formData.append("file", doc.file); // Assuming ed_data.file is a file object
      }
      formData.append("user_id", state?.user?.id);

      setBtnDisable(true);

      const url = `${process.env.REACT_APP_API_URL}api/add/document`;

      const headers = {
        Authorization: `Bearer ${state?.token}`,
        "Content-Type": "multipart/form-data", // Important for file upload
      };

      debouncedFormSubmit(formData, url, headers, (response) => {
        if (response?.data?.message === "document added successfully") {
          toast.success("Document get Successfully");
          setShow4(false);
          setBtnDisable(false);
        }
      });
    }
  };

  return (
    <>
      <div className="px-5 py-4">
        <h5 className="text-center font-600 mt-2 mb-4">
          Add Your Government Approved Document
        </h5>

        <SelectFile
          ref={inputRef.file}
          file={doc.file}
          setData={setDoc}
          onChange={onChange}
        />
        {error.file && (
          <p className="small mb-0 text-danger mt-1">Document is required</p>
        )}
      </div>
      <div className="text-center pb-5">
        <button
          disabled={btnDisable}
          onClick={() => {
            formSubmit();
          }}
          className="btn auth-btn clr-btn-theme px-5 mx-auto"
        >
          {btnDisable ? (
            <Loading stroke="4" width="20" height="20" />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </>
  );
}

const SelectFile = forwardRef(({ file, onChange, setData }, ref) => {
  return (
    <>
      <input
        name="file"
        type="file"
        id="file"
        className="d-none"
        onChange={onChange}
        ref={ref}
      />
      {file ? (
        <div className="select-file text-center py-2 position-relative">
          <img
            className="delete-icon pointer"
            src={recycleBin}
            alt="Delete"
            onClick={() => {
              setData((prev) => ({
                ...prev,
                file: "",
              }));
            }}
          />
          {typeof file === "string" ? (
            <img className="user-selected-file" src={file} alt="user-file" />
          ) : (
            <img
              className="user-selected-file"
              src={file && URL.createObjectURL(file)}
              alt="user-file"
            />
          )}
        </div>
      ) : (
        <div className="select-file py-3 position-relative">
          <label htmlFor="file" className="text-center pointer d-block">
            <img src={upload_Files} className="upload-file" alt="Upload" />
            <p className="small text-light mb-2">Attach evidence (optional)</p>
            <p className="btn btn-select-file mb-0">Select File</p>
          </label>
        </div>
      )}
      <p className="text-light small mt-2 mb-0">
        Enhance your submission by attaching relevant documents
      </p>
    </>
  );
});
