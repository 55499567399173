import React from "react";
import Playstore from "../Assets/playstore.png";
import Store from "../Assets/store.png";
import { Link } from "react-router-dom";

function Download() {
  return (
    <div className="p-3 mt-3 background-container same-passions">
      <h5 className="mb-sm-4 mb-3 main-heading">Download App</h5>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <img src={Playstore} alt="" className="store-image-size" />
          <p className="p-0 m-0 user-name">Play Store</p>
        </div>
        <Link to="https://play.google.com/store/apps/details?id=com.sageswedlock&pli=1">
          <button className="py-1 btn transparent-btn-theme">Download</button>
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <img src={Store} alt="" className="store-image-size" />
          <p className="p-0 m-0 user-name">App Store</p>
        </div>
        <Link to="https://play.google.com/store/apps/details?id=com.sageswedlock&pli=1">
          <button className="py-1 btn transparent-btn-theme">Download</button>
        </Link>
      </div>
    </div>
  );
}

export default Download;
